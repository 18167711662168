<template>
	<component :is="commonComponent" id="blog" title="Blogs">
	  <section id="blog-content" class="pc_padding">
		<h1 class="m-b-30 head-1">
		  You Think It's Normal, But It Could Be Long-Term Anxiety: 9 Signs
		</h1>
  
		<div class="autor m-b-30">
		  <div>Author: Lexi </div>
		  <div>Publish: 2024/9/10 </div>
		</div>
		<div class="slish m-b-20" />
  
		<AdComponent ref="ads-blog-1" class="m-b-40 " :ads="adsensConfig.blog_1"
		  :showDebug="showDebug" />
  
		<p>
		  Anxiety is an emotion everyone experiences, but few realize that anxiety disorders can
		  severely impact one&#39;s life. This psychological condition, commonly known as anxiety
		  disorder, can be hard to detect even by those suffering from it.
		</p>
  
		<p>
		  Many who have experienced anxiety disorder often look back and are surprised to find that
		  anxiety manifests not just psychologically but also physiologically, with symptoms that are
		  evident from start to finish.
		</p>
  
		<p>
		  These symptoms are not as noticeable as skin rashes or spots that quickly draw our
		  attention. Often, they blend seamlessly into daily habits and are mistaken for normal.
		</p>
  
		<p>
		  While having one or more of the following symptoms doesn&#39;t necessarily mean you have an
		  anxiety disorder, if they recur and medical tests rule out other causes, it might be time to
		  pay attention. Your body could be signaling that <strong>you are experiencing long-term
			anxiety.</strong>
		</p>
  
		<h2 class="head-2 m-t-60 m-b-30">
		  Frequent Stomach Discomfort
		</h2>
  
		<AdComponent ref="ads-blog-2" class="m-b-40 " :ads="adsensConfig.blog_2"
		  :showDebug="showDebug" />
  
		<p class="m-b-30">
		  <img class="border-radius-20" alt="img"
			src="@/assets/YmMwMGNmNWE5NzA3OTgxMDQyNmRiMDBkNDMxNzc2NTZfc3BFRmpVQUtSSlllNkhHZmpDUlJPdnI4ZVZJR0hlMEpfVG9rZW46UGhzU2I2dTY1b0N0Nzh4WmRYSGNlZ3Nibk9nXzE3MjYyMTE2NzE6MTcyNjIxNTI3MV9WNA.webp">
		</p>
  
		<p>
		  If you often feel the urge to use the restroom at work but don&#39;t have acute diarrhea
		  that clears out your system, you might be experiencing anxiety-related digestive issues.
		  Sometimes the discomfort eases, only to return later, creating a cycle of unease.
		</p>
  
		<p>
		  If medical tests don&#39;t reveal any other diseases, your digestive system might be
		  disrupted due to anxiety. When people are overly stressed, the sympathetic nervous system
		  can accelerate gastrointestinal contractions and movements, causing discomfort. The
		  &quot;brain-gut axis&quot; can also make visceral sensations more sensitive, leading to
		  abdominal pain. In some cases, the opposite occurs—constipation.
		</p>
  
		<p>
		  This condition is formally known as &quot;functional gastrointestinal disorder,&quot; often
		  referred to as &quot;the stomach is an emotional organ.&quot; Under anxiety, workdays become
		  torturous, and the restroom becomes a refuge.
		</p>
  
		<h2 class="head-2 m-t-60 m-b-30">
		  Changes in Appetite
		</h2>
  
		<p>
		  You might not remember when it started, but perhaps one late night after work, you suddenly
		  craved heavy, salty, and sweet foods like fried chicken, spicy hotpot, or cream
		  cakes—immediately and intensely.
		</p>
  
		<p>
		  It might have been after an argument with family or a partner, leaving you angry and
		  depressed, or maybe even earlier, during exam preparations while you were holed up in the
		  library.
		</p>
  
		<p>
		  In any case, you began craving certain strong-flavored foods, with varying intensity, over
		  a long period. On the internet, this might be joked about as &quot;pigging out,&quot; but it
		  could actually be emotional eating.
		</p>
  
		<p class="m-b-30">
		  <img class="border-radius-20" alt="img"
			src="@/assets/ZDk0NTEyN2U1MTAyMjU3OTRjZTk2N2RlOWM3NTA4NTFfWmVsRjJvaVdwcHFIMmVOTmY0NWY4S1cxMEN3eGtSbm9fVG9rZW46UXc1OWJkM0hTb3E5Q0R4eHAzVGN2WTVUbmhjXzE3MjYyMTE2NzE6MTcyNjIxNTI3MV9WNA.webp">
		</p>
  
		<p>
		  This is not physiological hunger but a sudden psychological urge to eat, often accompanied
		  by a craving for strong flavors. Essentially, under prolonged stress, the brain seeks
		  stimulation, which in some people manifests as increased or decreased appetite.
		</p>
  
		<p>Your body understands the pressure you&#39;re under more than you do.</p>
  
		<h2 class="head-2 m-t-60 m-b-30">
		  Frequent Urination
		</h2>
  
		<p>
		  Despite not drinking much water, you frequently feel the urge to urinate, often rushing to
		  the restroom only to produce little urine. Men might suspect prostate issues, but tests show
		  no urinary tract infections.
		</p>
  
		<p>
		  The key characteristic is that this frequent urge to urinate is more noticeable during the
		  day and diminishes at night when you&#39;re asleep. This could be due to muscle contractions
		  around the bladder or accelerated kidney filtration caused by long-term anxiety, leading to
		  urinary system disruptions.
		</p>
  
		<h2 class="head-2 m-t-60 m-b-30">
		  Compulsive Late-Night Staying Up
		</h2>
  
		<p>
		  Even when it&#39;s time to sleep, you can&#39;t bring yourself to go to bed. On one hand,
		  you know you need to get up early and have unfinished work, thinking, &quot;If I don&#39;t
		  sleep now, I won&#39;t wake up in time.&quot; On the other hand, you can&#39;t resist
		  staying up a bit longer, scrolling through your phone.
		</p>
  
		<p>
		  Many attribute this to a lack of self-control, but this compulsive late-night staying up
		  could be due to prolonged mental stress or intense brain activity, leading to autonomic
		  nervous system disorders.
		</p>
  
		<p>
		  Numerous clinical psychology studies have found a positive correlation between the severity
		  of bedtime procrastination and anxiety levels. Academically, this is known as &quot;revenge
		  bedtime procrastination.&quot;
		</p>
  
		<p>
		  We endure the day through compliance, but at night, those unnoticed anxieties surface
		  subtly.
		</p>
  
		<p class="m-b-30">
		  <img class="border-radius-20" alt="img"
			src="@/assets/YzI0ZGJhZjliNWJmYjc2MTYwMmY0MDg0Y2JkZjFlNzlfdlozeFVUYWdwSkdnRWhBaGUzeWN5bHd3Nm96cnFxSDhfVG9rZW46QVdPTmJzTVg1b25Kdnp4NFFvamNJaDJ1bndqXzE3MjYyMTE2NzE6MTcyNjIxNTI3MV9WNA.webp">
		</p>
  
		<h2 class="head-2 m-t-60 m-b-30">
		  Unconscious Hand Tremors
		</h2>
  
		<p>
		  Try extending your hands to see if they tremble slightly. A typical anxiety-induced hand
		  tremor patient might be deeply engrossed in work, oblivious to their physical sensations.
		  They might not notice their back pain until a colleague asks, &quot;Why are your hands
		  shaking?&quot;
		</p>
  
		<p>
		  Hyperthyroidism? The thyroid test is normal. Hypoglycemia? No. Parkinson&#39;s disease? Not
		  that either. It might be muscle tension from anxiety, causing tremors, spasms, and shaking.
		</p>
  
		<h2 class="head-2 m-t-60 m-b-30">
		  Frequent Eye Twitching
		</h2>
  
		<p>
		  This is another sign of muscle tension, known as eyelid spasms. Around our eye sockets are
		  muscles responsible for opening and closing the eyes—the &quot;orbicularis oculi.&quot; When
		  they contract excessively, the eyelids twitch uncontrollably, making it look like you&#39;re
		  winking.
		</p>
  
		<p>
		  This has nothing to do with superstitions about good or bad luck. It&#39;s a sign of
		  overuse, often due to fatigue, anxiety, and excessive mental stress. If this symptom
		  persists and is accompanied by facial muscle twitches, it could be &quot;tic
		  disorders,&quot; often comorbid with ADHD and obsessive-compulsive disorders.
		</p>
  
		<h2 class="head-2 m-t-60 m-b-30">
		  Shortness of Breath
		</h2>
  
		<p>
		  Feeling like a python is constricting your chest, causing sudden tightness that requires
		  deep breaths to alleviate. Besides potential heart issues, this could be due to overactive
		  autonomic nerves under long-term stress, leading to symptoms like rapid heartbeat, shortness
		  of breath, and dizziness.
		</p>
  
		<p>
		  Studies have found that these physical symptoms are particularly evident in individuals
		  with generalized anxiety disorder.
		</p>
  
		<h2 class="head-2 m-t-60 m-b-30">
		  Frequent Fidgeting
		</h2>
  
		<p>
		  After working for more than five minutes, you might unconsciously start scratching your
		  head or engaging in other repetitive behaviors. Picking at your hair, scratching your scalp
		  until it bleeds, and other unconscious actions might be seen as &quot;unhygienic&quot; by
		  those around you. However, these could be body-focused repetitive behaviors (BFRB), attempts
		  to soothe anxiety.
		</p>
  
		<p>
		  Other similar behaviors include picking at lips, biting nails, and chewing on cheeks. These
		  actions are often done without awareness or are hard to stop, indicating a dysregulation of
		  the sympathetic and parasympathetic nervous systems.
		</p>
  
		<h2 class="head-2 m-t-60 m-b-30">
		  Repetitive Actions
		</h2>
  
		<p>
		  Being meticulous might earn you a reputation for being a perfectionist. You might check the
		  gas, faucets, windows, and circuit breakers before leaving the house, and disinfect and wash
		  your hands upon returning.
		</p>
  
		<p>
		  But if you find yourself needing to spray alcohol exactly twenty times, wash your hands
		  three times, or repeatedly check windows and doors, this could be a sign of long-term
		  anxiety. More severe cases might involve an inability to tolerate any imperfections,
		  rewriting pages for a single mistake, or redrawing lines that aren&#39;t straight,
		  indicating obsessive-compulsive disorder.
		</p>
  
		<p>
		  Similar behaviors include &quot;repetitive reassurance-seeking,&quot; where you repeatedly
		  ask loved ones the same question, even if you&#39;ve just gotten a positive answer. For
		  example, &quot;Did I turn the key three times?&quot; &quot;Is my face symmetrical?&quot;
		  &quot;Am I your most important friend?&quot;
		</p>
  
		<p>These seemingly meaningless questions might be expressions of underlying insecurity.</p>
  
		<p class="m-b-30">
		  <img class="border-radius-20" alt="img"
			src="@/assets/OTllYjQ0YjIzYzljZDgwNTE1MTZlMTMyZDlkNjVlMThfOVJJYTFNTk9JaUtPcUg3OEZEYjhBMmVZOXVCejAzYlVfVG9rZW46UnJCRWI3MW9xb05qVU94YmZyb2M1eERMbmhmXzE3MjYyMTE2NzE6MTcyNjIxNTI3MV9WNA.webp">
		</p>
  
		<p>
		  If you identify with many of these signs, don&#39;t panic. Anxiety ≠ Anxiety Disorder. It
		  might just indicate that you&#39;re dealing with difficult situations or experiencing
		  significant stress recently.
		</p>
  
		<p>
		  Unnoticed or unexpressed anxieties don&#39;t disappear; they permeate daily habits. Anxiety
		  isn&#39;t entirely negative—it can alert us to prepare for the future and stay vigilant.
		  However, excessive vigilance can spiral out of control, leading to anxiety disorders that
		  severely impact our lives.
		</p>
  
		<p>
		  If you&#39;ve been experiencing unexplained, excessive anxiety for more than three months,
		  consider seeking help from a professional doctor.
		</p>
  
		<p>
		  Don&#39;t blame yourself for &quot;being so anxious.&quot; This indicates that, unbeknownst
		  to you, your body has been fighting anxiety for a long time.
		</p>
  
		<p>Give yourself a hug, gently pat yourself, and say:</p>
  
		<p>
		  <strong>&quot;You&#39;ve worked really hard. You&#39;ve done very well. Now, it&#39;s time
			to loosen up.&quot;</strong>
		</p>
	  </section>
	</component>
  </template>
  
  <script>
  import common from '@/mixin/common.js';
  import { mapGetters } from 'vuex';
  // 按需动态引入 PC 和 Mobile 版本的 CommonPage
  const PcCommon = () => import('@/pages/components/pc_common.vue');
  const MobileCommon = () => import('@/pages/components/m_common.vue');
  import AdComponent from '@/pages/components/AdComponent.vue'
  
  import '@/css/common.scss';
  import '@/css/blog_base.scss';
  
  export default {
	name: 'Blog1',
	components: {
	  AdComponent
	},
	mixins: [common],
	data () {
	  return {
		commonComponent: null,
	  };
	},
	metaInfo () {
	  return {
		title: "You Think It's Normal, But It Could Be Long-Term Anxiety: 9 Signs",
		meta: [
		  {
			name: 'description',
			content: "Anxiety is an emotion everyone experiences, but few realize that anxiety disorders can severely impact one's life. This psychological condition, commonly known as anxiety disorder, can be hard to detect even by those suffering from it."
		  },
		],
	  };
	},
	computed: {
	  ...mapGetters([
		'showDebug',
		'deviceType',
		'host',
		"adsensConfig",
  
	  ])
	},
	created () {
	  // 根据 deviceType 动态加载对应的组件
	  this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
	},
	async activated () {
	  console.log(`${this.$options.name} Component activated-----------`);
	  await this.loadAdSenseScript();  // 重新加载广告脚本
	},
	async mounted () {
	  console.log(`-------------------${this.$options.name} mounted`)
	  await this.loadAdSenseScript();  // 重新加载广告脚本
	},
	deactivated () {
	  console.log(`${this.$options.name} Component deactivated-----------`);
	},
	beforeDestroy () {
	  console.log(`-------------------${this.$options.name} beforeDestroy`);
	},
	methods: {
	  //广告相关start
	  async loadAdSenseScript () {
		// 其他需要在初次加载时执行的操作
		// 先检查广告是否已经加载
		if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
		  console.log("Adsense script already loaded.");
		  this.$eventrack('adscript_loaded', 'expose');
		  this.loadAdWithDelay()
		  return; // 如果已加载，直接返回
		}
		await this.loadScriptConditionally();
	  },
	  loadScriptConditionally () {
		console.log(this.adsensConfig);
		// 判断广告脚本URL是否存在
		if (!this.adsensConfig?.scriptUrl) {
		  console.log('广告脚本的URL不存在,终止加载广告外链');
		  this.$eventrack('no_adscript_config', 'expose');
		  return;
		}
		// 检查该脚本是否已经被添加
		const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
		if (existingScript) {
		  this.$eventrack('adscript_exist', 'expose');
		  console.log('脚本已存在，无需重新添加');
		  return;
		}
  
		console.log('准备插入脚本');
		const script = document.createElement('script');
		script.src = this.adsensConfig?.scriptUrl;
		script.crossOrigin = 'anonymous';
		script.async = true;
  
		const header = document.getElementsByTagName('head')[0];
		header.appendChild(script);
  
		this.$eventrack('adscript_add_success', 'expose');
		script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
		console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');
  
	  },
	  loadAdWithDelay() {
			setTimeout(() => {
				this.displayAd()
				this.setupIframeTracking()
			}, 500)
		},
		setupIframeTracking() {
			// let _this = this;
			console.log('ready to track iframe')
			const iframes = document.querySelectorAll('iframe')
			const tracker = new this.IframeTracker(this)
			iframes.forEach((iframe) => {
				tracker.addIframe(iframe, (iframeElement) => {
					console.log('Iframe 点击触发了!', iframeElement)
					const adContainer = iframeElement.closest('ins.adsbygoogle')

					if (adContainer) {
						// 获取父级 ins 元素的边界矩形
						const insRect = adContainer.getBoundingClientRect()

						// 计算父级 ins 元素相对于页面左上角的位置和高度
						const insX = insRect.left
						const insY = insRect.top
						const insHeight = insRect.height

						const windowWidth = window.innerWidth
						const windowHeight = window.innerHeight

						let transformData = {
							insX: insX,
							insY: insY,
							insHeight: insHeight,
							windowWidth: windowWidth,
							windowHeight: windowHeight,
						}
						this.$adClick(JSON.stringify(transformData))
						tracker.trackEvent('ad_iframe_click', 'click', {
							'data-ad-slot': adContainer.dataset.adSlot,
						})
					}
				})
			})
		},
		IframeTracker: class {
			constructor(vues, resolution = 200) {
				this.vues = vues // 保存 Vue 实例
				this.resolution = resolution
				this.iframes = []
				this.interval = null
				this.activeIframe = null // 存储最近被点击的iframe
				this.setupVisibilityTracking()
			}

			addIframe(element, callback) {
				const adContainer = element.closest('ins.adsbygoogle')
				const iframe = {
					element: element,
					callback: callback,
					hasTracked: false,
					adSlot: adContainer ? adContainer.dataset.adSlot : null,
				}
				this.iframes.push(iframe)

				if (!this.interval) {
					this.interval = setInterval(() => this.checkClick(), this.resolution)
				}
			}

			checkClick() {
				const activeElement = document.activeElement
				if (activeElement) {
					this.iframes.forEach((iframe) => {
						if (activeElement === iframe.element && !iframe.hasTracked) {
							iframe.callback(iframe.element)
							iframe.hasTracked = true
							this.activeIframe = iframe // 更新最近被点击的iframe
						}
					})
				}
			}

			setupVisibilityTracking() {
				const hiddenProperty = 'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null
				const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')

				const onVisibilityChange = () => {
					if (!document[hiddenProperty]) {
						console.log('页面激活状态,返回页面?')
						this.activeIframe = null // 重置最近被点击的iframe记录
					} else {
						console.log('页面非激活状态')

						if (this.activeIframe) {
							// 上报逻辑
							this.trackEvent('ad_iframe_click_leave', 'click', {
								'data-ad-slot': this.activeIframe.adSlot,
							})
						}
					}
				}

				this.iframeVisibilityTracker = onVisibilityChange
				document.addEventListener(visibilityChangeEvent, this.iframeVisibilityTracker)
			}

			trackEvent(eventName, eventValue, dataAttributes) {
				// 事件追踪逻辑
				console.log(`${eventName} with value ${eventValue} on`, dataAttributes)

				this.vues.$eventrack(eventName, eventValue, {
					...dataAttributes,
				})
			}
		},
		async displayAd () {
      await this.$nextTick()

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref) // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log('Adsense script not loaded yet, delaying ad display.')
        setTimeout(this.displayAd, 500) // 延迟再次尝试
        return
      }

      console.log(adsElements) // 检查是否包含 <ins> 标签
      adsElements.forEach((ad) => {
        console.log(ad) // 输出每个广告元素
        console.log('ready to push')
          ; (window.adsbygoogle = window.adsbygoogle || []).push({})
      })
    },
	  //广告相关end
	},
  };
  </script>
  